import styled from 'styled-components'

export const TourSiteWrapper = styled.div`
  line-height: 1.5;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: center;

  hr {
    display: none;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    max-width: 75%;
    margin: 0 auto;
    grid-gap: 10px;
    .desc {
      grid-row: 1;
    }
    .image {
      grid-row: 2;
    }
    hr {
      display: block;
    }
  }

  @media (max-width: 600px) {
    max-width: 95%;
  }

  img {
    border-radius: 15px;
    border: 3px solid var(--black);
  }

  h1 {
    margin-top: 0.25rem;
  }
`

export const Hr = styled.hr`
  margin: 1rem auto 0;
  border-top: 5px solid var(--secondary);
  width: 75%;
`
