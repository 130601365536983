import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Row from '../components/row'
import { FaQuestion } from 'react-icons/fa'

import { MaxWidthWrapper } from '../styledComponents/shared'
import { Hr, TourSiteWrapper } from '../styledComponents/tourSites'

import 'flag-icon-css/css/flag-icon.min.css'

const TourSitesPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title='Tour Sites'
        description='We have curated the best Great War sites that the continent has to offer, here are a few of them.'
        url='/tour-sites'
        image='tour_sites.jpg'
      />
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper maxWidth='700' style={{ lineHeight: '1.5' }}>
          <h1 style={{ marginTop: '0.25rem' }}>Tour Sites</h1>
          <h2>UK side</h2>
          <p>
            We start at a meeting point approximately 2 miles from the Eurostar
            terminal in Folkestone Kent. We will do a quick document check and
            then ride together to the Eurostar terminal.
          </p>
          <h2>Arriving on the continent</h2>
          <p>
            Once in Calais we ride together as a group to our accommodation in
            Belgium for the duration of our stay (approximately a 90 minute
            journey).
          </p>
          <p>
            On each tour there will be 2 tour guides, each with bluetooth
            communications, riding one at the front and one at the rear ensuring
            the group stays together.
          </p>
          <p>
            Once we have arrived at our accommodation it's time to drop off your
            bags and visit the first of many interesting and often humbling
            sites of the great war!
          </p>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--primary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper maxWidth='800' style={{ lineHeight: '1.5' }}>
          <h1>A plethora of destinations</h1>
          <p>
            The following are just some of the places you might visit and there
            are also some 'secret' destinations that we won't publish (we want
            to keep them to ourselves!), for obvious reasons we cannot publish a
            detailed itinerary on our website.
          </p>
          <p>
            If you have a specific place you would like to visit or have a
            fallen relative laid to rest in a certain cemetery and would like to
            visit then please let us know and we will, if possible, take you
            there.
          </p>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='desc'>
              <h1>Ypres</h1>
              <p>
                The battle of Ypres is often called the 3rd battle of
                Passchendaele. Between July and November 1917 Ypres was a city
                reduced to rubble, it was one of the bloodiest battles of WWI.
                Now rebuilt Ypres is a beautiful city full of history, character
                and charm.
              </p>
              <p>
                The last post ceremony is held at 8pm every night at the{' '}
                <span style={{ fontWeight: 700 }}>Menin gate</span> which has
                etched onto it the names of over 54,000 servicemen who are
                missing up to August 17th 1917 and have no known grave. The
                ceremony is always very moving with huge crowds of people from
                all over the world paying their respects to the fallen and
                missing of the great war. The cloth hall in the city centre is
                also a very interesting place to visit with an excellent museum
                telling the story of the 3rd battle of Passchendaele.
              </p>
            </div>
            <div className='image'>
              <Img fluid={data.ypres.childImageSharp.fluid} />
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='image'>
              <Img fluid={data.tyneCot.childImageSharp.fluid} />
            </div>
            <div className='desc'>
              <h1>Tyne Cot Cemetery</h1>
              <p>
                The largest of all commonwealth cemeteries and the final resting
                place of 11,965 servicemen from all over the commonwealth. The
                cemetery has 8,370 graves of nameless soldiers of the great war
                known only unto God. The names of 34,991 soldiers are etched
                onto the walls of Tyne Cot, these are servicemen missing from
                August 16th 1917 and have no known grave.
              </p>
              <p>
                Within the cemetery and still visible are 3 German pillboxes,
                with the fourth being covered at the suggestion from King George
                V.
              </p>
              <p>
                Visitng Tyne Cot is a deeply moving experience. The sight of so
                many graves brings a realisation of what the First World War
                meant, and the scale it was fought on.
              </p>
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='desc'>
              <h1>Memorial Museum Passchendaele 1917</h1>
              <p>
                The Memorial Museum Passchendaele 1917 is a military history
                museum telling the story of the First World War with a focus on
                the Battle of Passchendaele through a thematic approach. The
                Third Battle of Ypres in 1917 is the pivotal event around which
                the museum is developed. The museum is located centrally in the
                former battlefield.
              </p>
              <p>
                The location and its surroundings still show the scars of that
                time. The Museum is the gateway to this hertiage.
              </p>
            </div>
            <div className='image'>
              <Img fluid={data.passchendaele.childImageSharp.fluid} />
              <p
                style={{
                  margin: '0.2rem 0 0.2rem',
                  backgroundColor: 'var(--black)',
                  color: 'var(--secondary)',
                  display: 'inline-block',
                  padding: '0.2rem',
                  fontSize: '0.6rem',
                  borderRadius: '4px',
                  fontStyle: 'italic'
                }}
              >
                <a href='https://commons.wikimedia.org/wiki/File:Het_kasteel.JPG'>
                  Image source: MMP1917
                </a>
              </p>
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='image'>
              <Img fluid={data.talbotHouse.childImageSharp.fluid} />
            </div>
            <div className='desc'>
              <h1>Talbot House (TOC H)</h1>
              <p>
                In early summer 1915 M. Camerlynck was pleased to offer his
                empty house for rent to the British Army for 150 Francs a month.
                An Army Chaplain, the Reverend Philip "Tubby" Clayton saw a use
                for the property as a soldier's club. It became a rare place
                where soldiers could meet and relax regardless of rank, a true
                Every-Man's Club.
              </p>
              <p>A notice was hung by the front door bearing the message:</p>
              <p style={{ fontStyle: 'italic' }}>
                "All rank abandon, ye who enter here."
              </p>
              <p>
                The house was named Talbot House in memory of Lieutenant Gilbert
                W. L. Talbot, aged 23, Gilbert was serving with the 7th
                Battalionwhen he was killed at Hooge in the Ypres Salient on
                July 30th 1919. He is buried in Sanctuary Wood British Military
                Cemetery, Zillebeke, near Ypres.
              </p>
              <p>
                The name Talbot House was soon shortened to TOC H. TOC was the
                British Army signallers code for 'T' and H was 'H'
              </p>
              <p>
                This is a wonderful museum and one of our favourite places to
                visit.
              </p>
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='desc'>
              <h1>Shot at Dawn Death Cells</h1>
              <h2>Execution Spot Poperinge</h2>
              <p>
                The authetic execution pole, located in the inner courtyard of
                the town hall, is a painful reminder of the fate which awaited
                many so-called 'deserters' during the Great War.
              </p>
              <p>
                Most of these frightened men were suffering from shell shock.
                Soldiers, who were sometimes bombarded for days on end, became
                near senseless and simply did not know what they were doing.
                Instead of receiving compassion and understanding, a terrifying
                example was made of them. The condemed men spent their last
                night in the death cell before being shot at dawn on the
                courtyard.
              </p>
            </div>
            <div className='image'>
              <Img fluid={data.shotAtDawn.childImageSharp.fluid} />
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='image'>
              <Img fluid={data.hill62.childImageSharp.fluid} />
              <p
                style={{
                  margin: '0.2rem 0 0.2rem',
                  backgroundColor: 'var(--black)',
                  color: 'var(--secondary)',
                  display: 'inline-block',
                  padding: '0.2rem',
                  fontSize: '0.6rem',
                  borderRadius: '4px',
                  fontStyle: 'italic'
                }}
              >
                <a href='https://commons.wikimedia.org/wiki/File:Canadian_Hill_62_Memorial_(DSCF9375).jpg'>
                  Image source: Trougnouf (Benoit Brummer)
                </a>
              </p>
            </div>
            <div className='desc'>
              <h1>Hill 62 Sanctuary Wood Museum and Original Trenches</h1>
              <p>
                This incredible museum is now one of the few places on the Ypres
                Salient battlefields where an original trench layout can be
                seen. The indoor section of the museum collection contains
                equipment removed from the battlefield in the vicinity of
                Sanctuary Wood. Also included are stereoscopic photos that
                whilst fascinating are also somewhat disturbing. This museum is
                a must see on any tour.
              </p>
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='desc'>
              <h1>Beaumont Hamel Original Trenches and Battlefield</h1>
              <p>
                Newfoundland Memorial Park is a site on the Somme battlefield
                near to Beaumont Hamel. The park was named after the Royal
                Newfoundland Regiment, which had provided one battalion of 800
                men to serve with the British and Commonwealth Armies. Its
                tragic part in the action of 1 July 1916 is remembered through
                this memorial park. The site is also a memorial to all the
                Newfoundlanders who fought in the First World War, most
                particularly those who have no known grave.
              </p>
            </div>
            <div className='image'>
              <Img fluid={data.beaumontPark.childImageSharp.fluid} />
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='image'>
              <Img fluid={data.sommeMuseum.childImageSharp.fluid} />
              <p
                style={{
                  margin: '0.2rem 0 0.2rem',
                  backgroundColor: 'var(--black)',
                  color: 'var(--secondary)',
                  display: 'inline-block',
                  padding: '0.2rem',
                  fontSize: '0.6rem',
                  borderRadius: '4px',
                  fontStyle: 'italic'
                }}
              >
                <a href='https://commons.wikimedia.org/wiki/File:Museumsomme_28-09-2008_13-43-13.JPG'>
                  Image source: Paul Hermans
                </a>
              </p>
            </div>
            <div className='desc'>
              <h1>The Somme Museum Albert</h1>
              <p>
                Located in the centre of Albert, a town which is the symbol of
                World War I, this museum goes back over the soldiers' life in
                the trenches during the offensive of 1 July 1916. There are
                about 15 alcoves and display cases in a 230 metre-long
                underground that was used as an air-raid shelter during World
                War Two. Sound effects, images and lights throw the visitors
                into the daily life experienced by these soldiers.
              </p>
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='desc'>
              <h1>Langemark German Cemetery</h1>
              <p>
                Langemark cemetery is one of only four First World War German
                cemeteries in the Flanders region. Adolf Hitler himself visited
                this cemetery in June 1940. Where British and commonwealth
                cemeteries are quite beautiful in appearance German cemeteries
                are quite dark in appearance. In total 44,294 men are laid to
                rest at Langemark in an area a quarter of the size of Tyne Cot,
                24,916 in one mass grave called the comrades grave.
              </p>
            </div>
            <div className='image'>
              <Img fluid={data.langemark.childImageSharp.fluid} />
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='image'>
              <Img fluid={data.hooge.childImageSharp.fluid} />
            </div>
            <div className='desc'>
              <h1>Hooge Crater Museum and battlefield</h1>
              <p>
                Almost certainly the best private museum in Belgium and a must
                visit.
              </p>
              <p>
                In the summer of 1915, the British positions around Hooge have
                become precarious. From their vantage point the Germans here
                have a good view over the British frontline. With a limited but
                well targeted attack, the British try to eliminate German
                strongholds. On 19 July 1915 they detonated a charge of 1,700
                kilograms of explosives in a tunnel that had been dug by the
                special Tunnelling Companies of the Royal Engineers. Immediately
                after the explosion the allies rush the crater in order to
                consolidate their advance. Later it was referred to as 'the
                Hooge Crater’.
              </p>
              <p>
                All in all, the museum enables every visitor to immerse into the
                life of a soldier at the front.
              </p>
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='desc'>
              <h1>Lochnagar Crater</h1>
              <p>
                The Lochnagar mine crater on the 1916 Somme battlefields in
                France is the largest man-made mine crater created in the First
                World War on the Western Front. It was laid by the British
                Army's 179th Tunnelling Company Royal Engineers underneath a
                German strongpoint called “Schwaben Höhe”. The mine was exploded
                two minutes before 07.30 am Zero Hour at the launch of the
                British offensive against the German lines on the morning of 1st
                July 1916. The explosion was heard in London. This is a
                fascinating place to visit.
              </p>
            </div>
            <div className='image'>
              <Img fluid={data.lochnagar.childImageSharp.fluid} />
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='image'>
              <Img fluid={data.thiepval.childImageSharp.fluid} />
              <p
                style={{
                  margin: '0.2rem 0 0.2rem',
                  backgroundColor: 'var(--black)',
                  color: 'var(--secondary)',
                  display: 'inline-block',
                  padding: '0.2rem',
                  fontSize: '0.6rem',
                  borderRadius: '4px',
                  fontStyle: 'italic'
                }}
              >
                <a href='https://commons.wikimedia.org/wiki/File:Thiepval_Memorial_and_Anglo-French_Cemetery_(September_2010)_2.JPG'>
                  Image source: Carcharoth
                </a>
              </p>
            </div>
            <div className='desc'>
              <h1>Thiepval Memorial</h1>
              <p>
                The Thiepval Memorial, the Memorial to the Missing of the Somme,
                bears the names of more than 72,000 officers and men of the
                United Kingdom and South African forces who died in the Somme
                sector before 20 March 1918 and have no known grave. Over 90% of
                those commemorated died between July and November 1916.
              </p>
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='desc'>
              <h1>Verdun</h1>
              <p>
                Verdun was the site of a major battle, and the longest-lasting,
                of the First World War. One of the costliest battles in military
                history, Verdun exemplified the policy of a "war of attrition"
                pursued by both sides, which led to an enormous loss of life and
                very large casualty lists.
              </p>
            </div>
            <div className='image'>
              <Img fluid={data.verdun.childImageSharp.fluid} />
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--primary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <div
            style={{
              textAlign: 'center',
              maxWidth: '500px',
              lineHeight: '1.5',
              margin: '0 auto'
            }}
          >
            <h1 style={{ marginTop: '0.5rem' }}>World War II</h1>
            <p>
              Although we specialise in World War I battle sites we also have a
              number of incredible historical World War II sites/museums that
              are sure to leave you amazed. These sites can be added to our
              itinerary with group approval.
            </p>
          </div>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='image'>
              <Img fluid={data.laCoupole.childImageSharp.fluid} />
            </div>
            <div className='desc'>
              <h1>La Coupole</h1>
              <p>
                Constructed in the side of a disused chalk quarry, the most
                prominent feature of the complex is an immense concrete dome, to
                which its modern name refers.
              </p>
              <p>
                It was built above a network of tunnels housing storage areas,
                launch facilities and crew quarters. The facility was designed
                to store a large stockpile of V-2s, warheads and fuel and was
                intended to launch V-2s on an industrial scale. Dozens of
                missiles a day were to be fuelled, prepared and launched in
                rapid sequence against London and southern England. This is an
                incredible museum and a must see.
              </p>
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <TourSiteWrapper>
            <div className='desc'>
              <h1>Le Blockhaus d’Eperlecques</h1>
              <p>
                Built between March 1943 and July 1944, Le Blockhaus
                d’Eperlecques was originally intended to be a launching facility
                for the V-2 (A-4) ballistic missile. It was designed to
                accommodate over 100 missiles at a time and to launch up to 36
                daily.
              </p>
              <p>
                The facility would have incorporated a liquid oxygen factory and
                a bomb-proof train station to allow missiles and supplies to be
                delivered from production facilities in Germany. It was
                constructed using the labour of thousands of prisoners of war
                and forcibly conscripted workers used as slave labourers.
              </p>
              <p>
                The bunker was never completed as a result of the repeated
                bombing by the British and United States air forces as part of
                Operation Crossbow against the German V-weapons programme.
                Today, the bunker is preserved as part of a privately-owned
                museum that presents the history of the site and the German
                V-weapons programme and is an incredible museum.
              </p>
            </div>
            <div className='image'>
              <Img fluid={data.blockhaus.childImageSharp.fluid} />
            </div>
            <Hr />
          </TourSiteWrapper>
        </MaxWidthWrapper>
      </Row>
      <Row bgColour='var(--primary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper>
          <div
            style={{
              textAlign: 'center',
              maxWidth: '500px',
              lineHeight: '1.5',
              margin: '0 auto'
            }}
          >
            <h1 style={{ marginTop: '0.5rem' }}>Secret sites</h1>
            <p>
              We have several 'secret' places we visit that we don’t publish and
              at least one of them is included on all tours, these sites are
              hard to find and are by appointment only.
            </p>
            <FaQuestion style={{ fontSize: '2rem', marginBottom: '1rem' }} />
          </div>
        </MaxWidthWrapper>
      </Row>
    </Layout>
  )
}

export default TourSitesPage

export const query = graphql`
  query TourSitesPageQuery {
    site {
      siteMetadata {
        title
        tourPrice
        email
        phone
        maxWidth
      }
    }
    ypres: file(relativePath: { eq: "ypres.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tyneCot: file(relativePath: { eq: "tynecot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    talbotHouse: file(relativePath: { eq: "talbothouse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shotAtDawn: file(relativePath: { eq: "shotatdawn.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hill62: file(relativePath: { eq: "hill62.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sommeMuseum: file(relativePath: { eq: "somme.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    langemark: file(relativePath: { eq: "langemark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hooge: file(relativePath: { eq: "hooge.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thiepval: file(relativePath: { eq: "thiepval_attr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lochnagar: file(relativePath: { eq: "lochnagar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    verdun: file(relativePath: { eq: "verdun.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    laCoupole: file(relativePath: { eq: "la_coupole.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blockhaus: file(relativePath: { eq: "blockhaus.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beaumontPark: file(relativePath: { eq: "beaumontPark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    passchendaele: file(relativePath: { eq: "passchendaele_attr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
